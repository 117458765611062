import React from "react";
import { Provider } from "react-redux";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import { ShoppingCartProvider } from "./components/_default/context/ShoppingCartContext"
import store from "./store/store"
import Redirect from "./components/_default/utilities/Redirect"
import TagManager from 'react-gtm-module'
// Pages
import Webpage1Avaleht from "./pages/Webpage1Avaleht";
import Webpage2Tellimus from "./pages/Webpage2Tellimus";
import Webpage4Privaatsuspoliitika from "./pages/Webpage4Privaatsuspoliitika";
import Webpage5K__psised from "./pages/Webpage5K__psised";
import Webpage6Toode from "./pages/Webpage6Toode";

var baseURL = 'https://almaregrill.kassahaldus.ee/webstoreApi';
var axiosHeaders = {
    Authorization: 'Bearer eyJ0eXAiOiJKV1QiLCJhbGciOiJSUzI1NiJ9.eyJhdWQiOiI0IiwianRpIjoiNGE2NzVmMmRlNTdmMTZmYjQxNjQzMTYzODk0NWFhOGJmNjMyN2QwZTQ2MGYzMzA3MzIxOGFjOTUxMWJhM2JkMWIzZDU1NWZjNjMyYzNhNGMiLCJpYXQiOjE3MzM1MDA4ODEuNjA5NjI5LCJuYmYiOjE3MzM1MDA4ODEuNjA5NjM3LCJleHAiOjE3MzM1ODcyODEuNjA0OTg2LCJzdWIiOiI4Iiwic2NvcGVzIjpbXX0.mXS4H0cxdsTeOuMtn2lWtffiR7AsNLbzLF5Wb2_CyGgplR61n-qq5ysYq8lU4xSxZXGeB5lw8zAQQBsMXcV2qti22UaC4QUZOieAjeucfQ_OAkdkVuhuPhr9qorHTPe1EgaB4h83WLPHvt3hs6V8aV--DRoDngK22bNzSeNn5kh0vk_L_3u6SXxqGVbdrpch3zl0J784B9wgCQKYht5dvwqPuk4sD_IZHbetysjTITznHgsU0yWQb112OYp60jMq4Jdv_8T4X1O8Twew66OUIWYloyo-X4d8x03HHojYtz1wPQL__21Aoaxg-KVLuW4twGop6hjY7e_2jLITYrElbU0DjrWZguSoh5cgZjFqigHrfvasKVfw_Gk0r_iXHsVIoEUp6__gAbFYuK56C4hN8QlBqJq1uQ4_EZkWlOFLKrtPOVf_LYkNeYtsPdDGjXmwicTOYc7mvzZ5omq1J9LmHhmfKWo0256Fm83sugti8za-KSSw8Y0XulEpNH7Po5YtmvaQoxB7nWFEz7Cep_mgUhfUtqhrH7Cu10lGyhfRndiFE_ClUCnJQBfFTzBp8T2B5Ctr0DSqqMpQ0btBKlwngauwK7ruuOwSifQM4CSpWBgPV-71sYMRiiRwz4Scoq4Ca7BLJFIgA4k89N4S8h-ClkzfXdLBIuNqVieWkC0ImTo',
    'Access-api-key': "eyJpdiI6Ik9aUzZ1REpiNnM3Rzg1VUkyMG02NVE9PSIsInZhbHVlIjoiYlE2Q0NmSU1BMUlVTm5MVFE2K3kvdz09IiwibWFjIjoiNjA4NTA4ZDAzMDRiMGNiMDM1YmQ3OWUxZGI2YmQ1NGI0ZThmM2E2MTViYmU4N2ExMWVjMmMwOTkzNzEzYzJhMyIsInRhZyI6IiJ9",
    'Access-api-id': "eyJpdiI6Im9kYTlkNjloZ09Sa3NPRURmNCtXK3c9PSIsInZhbHVlIjoiSVREL2hYS0NOYURrYkJSd1NQZVZndz09IiwibWFjIjoiYjI5ZjUxNjZhNTdkMjE0OTYyMDQ3ZDI0ZGVkMzRkYmM5MWY5ZDVmMjc4Yzg5Y2E3ZTg5NDliNWE4ZGQyMmY3NyIsInRhZyI6IiJ9",
    'Access-Control-Allow-Origin': "*"
 };


window.axios = require('axios').create({
  baseURL: baseURL,
  headers: axiosHeaders
});
const tagManagerArgs = {
    gtmId: "GTM-KZ74CBG6"
}
TagManager.initialize(tagManagerArgs)
    
export default function App() {
    window.dataLayer.push({
        event: 'pageview'
    });
    return (
        <Provider store={store}>
            <ShoppingCartProvider>
                <BrowserRouter basename="/">
                    <Routes>
                                            <Route path="/et/" element={<Webpage1Avaleht />} exact />;
                                                                    <Route path="/et/tellimus" element={<Webpage2Tellimus />} exact />;
                                                                    <Route path="/et/privacyPolicy" element={<Webpage4Privaatsuspoliitika />} exact />;
                                                                    <Route path="/et/cookiePolicy" element={<Webpage5K__psised />} exact />;
                                                                    <Route path="/et/toode/:id" element={<Webpage6Toode />} exact />;
                                                                
                                                                <Route path="*" element={<Webpage1Avaleht />} exact />;
                                        </Routes>
                </BrowserRouter>
            </ShoppingCartProvider>
        </Provider>
    );
}